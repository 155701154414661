const lang = {
  common: {
    add:  'Thêm',
    edit: 'Sửa', 
    delete: 'Xóa', 
    cancel: 'Hủy', 
    submit: 'Gửi', 
    confirm: 'Xác nhận', 
    done: 'Hoàn tất', 
    buy: 'Mua',
    close: 'Đóng',
    today: 'Hôm nay',
    signup: "Đăng ký",
    login: "Đăng nhập",
    logout: 'Đăng xuất',
    noData: 'Không có dữ liệu', 
    noMore: 'Không có thêm',
    loading: 'Đang tải',
    downloading: 'Đang tải xuống',
    sms: 'Gửi OTP',
    smsColdDown: 'Lấy lại sau',
    second: ' S',
    modify: 'Sửa đổi',
  },
  placeholder: {
    default: 'Vui lòng nhập', 
    email: "Nhập Email",
    minPrice: 'Số nhỏ nhất', 
    maxPrice: 'Số lớn nhất',
    rechargeAmount: 'Nhập số tiền muốn nạp',
  },
  tabbar: {
    game: 'Trò chơi',
    signIn: 'Đăng nhập',
    my: 'Tài khoản',
  },
  label: {
    account: 'Tài khoản',
    email: "Email",
    mail: "Email", 
    phone:  "Số điện thoại",
    name: "Họ và tên", 
    pwd: "Mật khẩu",
    confirmPwd: "Xác nhận mật khẩu",
    oldPwd: "Mật khẩu cũ",
    newPwd: "Mật khẩu mới",
    referralCode: "Mã giới thiệu", 
    referralCodeOptional: "Mã giới thiệu (Tuỳ chọn)", 
    captcha:"CAPTCHA (Tôi không phải robot)", 
    verifyCode: 'Vui lòng Mã OTP',
    name: 'Tên tài khoản',
    active: 'Chỉ số tích cực',
    additional: 'Hoa hồng',
    date: 'Ngày',
    item: 'Mục', 
    amount: 'Số tiền',
    address:  'Địa chỉ',
    quantity: 'Số lượng',
    status: 'Trạng thái',
    rechargeAmount:  'Số tiền nạp',
    withdrawAmount: 'Số tiền rút',
    bankcard: 'Ngân hàng',
  },
  msg: {
    logout: 'Thông tin đăng nhập không bị xóa khi đăng xuất, vẫn có thể sử dụng tài khoản này vào lần đăng nhập sau.',
    invalidRechargeNumber: 'Định dạng số tiền nạp không đúng',
    productLock: 'Để mở khóa lựa chọn này, bạn phải lên cấp độ trước',
    noFilled :'Không có thông tin được điền trước đây',
    outOfStock: 'Hết hàng',
    flashSoldOut: 'Sản phẩm này đã bán hết, thử lại lần sau',
    insufficientEgg: 'Không đủ Wcoin',
    buyProduct: 'Bạn chắc chắn mua đơn hàng này?',
    orderSuccess: 'Đơn hàng của bạn được đặt thành công',
    pwdNotEqual: 'Mật khẩu không khớp!',
  },
  notify: {
    operateSuccess: 'Xử lý hoàn tất',
    copySuccess: 'Đã Copy',
    sentSuccess: 'Đã gửi',
  },
  signup: {
    title: "Nơi sức khỏe và tài phú hội tụ",
  },
  forgetPassword: {
    title: 'Lấy lại mật khẩu',
    forget: 'Quên mật khẩu?',
  },
  my: {
    teamActivity: 'Dáo cáo đội nhóm',
    securityCenter: 'Trung tâm bảo mật',
    userInfo: 'Thông tin cá nhân',
  },
  // store
  store: {
    eggs: 'Wcoin',
    balance: 'Số dư khả dụng',
    history: 'Lịch sử',
    market: 'Cửa hàng',
    flashSale: 'Flash Sale',
    above: 'Bên trên',
    sold: 'Đã bán [[1]]',
    placeOrder: 'Đặt hàng',
    start: 'Bắt đầu',
    reserved: 'Đã đặt trước',
    pcs: '[[1]]sản phẩm cuối cùng',
    reserve: 'Đặt trước',
    grab: 'Giao hàng ngay',
    reminder: 'Nhắc nhở',
    detail: 'Chi tiết',
    description: 'Miêu tả',
    totalAmount: 'Tổng số tiền',
    receipt: 'Biên lai',
    orderHistory: 'Lịch sử đặt hàng',
  },
  lucky: {
    title: 'Cửa hàng',
    myBalance: 'Số dư của tôi',
    current: 'Hiện tại',
    previous: 'Trước đó',
    my: 'Của tôi',
    join: 'Tham gia',
    notJoin: 'Không tham gia',
    joinCount: '[[1]] người tham gia',
    joinNeed:'Tham gia:',
    prize: 'Phần thưởng',
    bet: 'Đặt cược',
    process: 'Xử lý',
    rules: 'Luật lệ',
    participates: 'Tham gia',
    details: 'Chi tiết',
    quantity: 'Số lượng',
    drawLottery: 'Vé số',
    quantityTitle: 'Chọn số lượng tham gia',
    winPrice: 'Người thắng',
    minRequire: 'Tích lũy tối thiểu',
    haveJoined: 'Bạn đã tham dự',
    times: 'lần',
    drawNumber: 'Thẻ cào',
    lotteryNumber: 'Kết quả trúng thưởng',
    winNumber: 'Số thắng cuộc',
    waitDrawTime: 'Chờ đến lượt cào',
    drawTimes: 'Có [[1]] lần cào',
    joinSuccess: 'Tham dự thành công',
    joinEnd: 'Kết thúc tham dự',
    seeMore: 'Xem thêm',
    total: 'Tổng',
    worth: 'Giá trị',
    status: {
      win: 'Thắng',
      lose: 'Thua',
      waitDrawn: 'Chờ để cào',
    },
    coinCode: {
      GAME: 'Wcoin',
      USDT: 'USDT',
      VND: 'VND',
    }
  },
  journal: {
    title: 'Nhật ký',
  },
  // ce
  ce: {
    eggs: 'Wcoin',
    currency: 'Tiền tệ',
    rate: 'Tỷ lệ',
    amount: 'Số lượng',
    uAmount: 'Số lượng USDT',
    confirm: 'Xác nhận',
    buy: 'Mua',
    sell: 'Bán',
    withdraw: 'Rút tiền',
    deposit: 'Nạp tiền',
    cancel: 'Hủy',
    type: 'Loại hình',
    address: 'Địa chỉ',
    bank: 'Ngân hàng',
    bankAccount: 'Số tài khoản',
    name: 'Họ và tên',
    details: 'Chi tiết',
    status: 'Trạng thái',
    referenceNo: 'Mã giao dịch',
    dateTime: 'Ngày & Giờ',
    paidAmount: 'Số tiền trả',
    IWantTo: 'Tôi muốn',
    unitPrice: 'Đơn vị tiền',
    marketPrice: 'Giá thị trường',
    highestPrice: 'Giá cao nhất',
    lowestPrice: 'Giá thấp nhất',
    quantity: 'Số lượng',
    minQuantity: 'Số lượng nhỏ nhất',
    saleFee: 'Phí giao dịch',
    totalAmount: 'Tổng tiền',
    saleAmount: 'Số lượng giao dịch',
    all: 'Tất cả',
    price: 'Giá',
    note: 'Ghi chú',
    processing: 'Đang xử lý',
    buyer: 'Người mua',
    seller: 'Người bán',
    revoke: 'Thu hồi',
    transaction: 'Giao dịch',
    receivedAmount: 'Số tiền nhận được',
    fee: 'Phí',
    done: 'Hoàn tất',
    limit: 'Giới hạn',
    available: 'Có sẵn',
    min: 'Nhỏ nhất',
    paymentMethod: 'Hình thức thanh toán',
    successfullyDeposit: 'Giao dịch thành công',
    successfullyWithdraw: 'Giao dịch thành công',
    successfullyPosted: 'Giao dịch thành công!',
    revokeTips: 'Bạn chắc chắn thu hồi đơn hàng?',
    levelTips: 'Lên Level để giảm phí giao dịch',
    modal: {
      tips1: 'Bằng cách tiếp tục, bạn đã đọc và đồng ý với',
      tips2: ' Tuyên bố miễn trừ trách nhiệm về giao dịch P2P của chúng tôi',
    },
    table: {
      tips1: 'Giao dịch cuối cùng của bạn',
      tips2: 'sẽ phản ánh trong vòng 24 giờ.',
    },
    home: {
      title: 'Sàn giao dịch',
      stats: 'Thống kê chung',
      chatBuy: 'Giá mua',
      chatsell: 'Giá bán',
      priceCB: 'Giá mua hiện tại',
      priceCS: 'Giá bán hiện tại',
      priceTB: 'Giá mua hôm nay',
      priceTS: 'Giá bán hôm nay',
      priceHB: 'Giá mua cao nhất',
      priceLB: 'Giá mua thấp nhất',
      priceHS: 'Giá bán cao nhất',
      priceLS: 'Giá bán thấp nhất',
      tips: 'Chỉ mang tính chất tham khảo',
    },
    recharge: {
      title: 'Trung tâm nạp tiền',
    },
    payment: {
      title: 'Chi tiết giao dịch',
    },
    market: {
      title: 'Trung tâm lệnh',
    },
    exchange: {
      title: 'Trao đổi',
      tips1: 'Vui lòng đọc kỹ chi tiết khi thực hiện',
      tips2: 'một giao dịch để ngăn chặn thất bại',
    },
    history: {
      title: 'Lịch sử giao dịch',
    },
    report: {
      title: 'Báo cáo',
    },
    success: {
      depositTips: 'Giao dịch của quý khách đang được xử lý. Vui lòng kiên nhẫn chờ đợi trong ít phút để chúng tôi hoàn tất quá trình xác nhận.',
      withdrawTips: 'Giao dịch của quý khách đang được xử lý. Vui lòng kiên nhẫn chờ đợi trong ít phút để chúng tôi hoàn tất quá trình xác nhận.',
      intentTips: 'Giao dịch của quý khách đang được xử lý. Vui lòng kiên nhẫn chờ đợi trong ít phút để chúng tôi hoàn tất quá trình xác nhận.',
      exchangeTips1: 'Hoàn tất! Câu lạc bộ đi bộ của bạn',
      exchangeTips2: 'Đã được tài trợ trong ví giao dịch của bạn.',
    },
    // 3-31新增
    buyPrice: 'Giá mua',
    sellPrice: 'Giá bán',
    // 撮合市场新增
    limitOrder: 'Lệnh giới hạn',
    MaxTo: 'tối đa ',
    total: 'Tổng cộng',
    transactionFee: 'Phí giao dịch',
    validate: {
      required: 'Vui lòng nhập giá trị',
      pattern: 'Giá trị được định dạng không chính xác'
    },
    levelUp: 'Nâng cấp',
    // 7-17新增
    rechargeMethod: 'Phương thức nạp tiền',
    withdrawMethod: 'Rút tiền về',
    select: 'Chọn',

    insufficientBalance: 'Số dư không đủ',
    convertCurrency: 'Vui lòng quy đổi tiền tệ',
    moneyChange: 'Đổi tiền',
    exchangeRate: 'Tỷ giá',
    exchange: 'Quy đổi',
  },
  // inbox
  inbox: {
    title: 'Nhắn tin',
    notification: 'Thông báo',
    messages: 'Tin nhắn',
  },
  // game
  game: {
    live: {
      title: 'Trò chơi',
      viewAll: 'Xem tất cả',
      seeAll: 'Xem tất cả',
    },
    search: 'tìm kiếm',
    history: 'Lịch sử',
    discovery: 'Khám phá',
  },
  // attendance
  attendance: {
    signedDays1: 'Điểm danh liên tiếp ',
    signedDays2: ' ngày',
    nextDayReward: 'Quay lại vào ngày mai nhận thưởng [[1]] VND',
    dailySignIn: 'Điểm danh mỗi ngày',
    signIn: 'Điểm danh ngay',
    signed: 'Đã điểm danh',
    day: 'Ngày [[1]]',
  },
  task: {
    rewardTask: 'Nhiệm vụ thưởng',
    toComplete: 'Hoàn thành',
    completed: 'Hoàn thành',
  },
  ad: {
    timeToGetReward: '[[1]] giây để nhận thưởng',
    receivedReward: 'Đã nhận được phần thưởng',
    notSupportVideo: 'Trình duyệt của bạn không hỗ trợ thẻ video.'
  },
}

export default lang;
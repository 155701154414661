import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'lib-flexible';
import moment from 'moment';
import * as plugins from '@/plugins'
import i18n from '@/assets/lang/index';
import precision from '@/utils/precision'
import filter from '@/utils/filter';


const app = createApp(App)

app.use(router);
plugins.setPinia(app);
plugins.setComponents(app);
plugins.setBridge(app);
plugins.setupDirective(app)
app.use(i18n);

app.mount('#app');

// app.config.globalProperties.$primary = '#4E62E4';
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$filter = filter;

String.prototype.format = function() {
  if(arguments.length == 0) return this;
  var obj = arguments[0];
  var s = this;
  for(var key in obj) {
    s = s.replace(new RegExp("\\[\\[" + key + "\\]\\]", "g"), obj[key]);
  }
  return s;
}

let u = navigator.userAgent;
let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
if(isAndroid) {
   window.addEventListener('resize', function() {
    if(document.activeElement.tagName=='INPUT' || document.activeElement.tagName=='TEXTAREA') {
      window.setTimeout(function() {
        document.activeElement.scrollIntoView();
      }, 0);
    }
   })
}
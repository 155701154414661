import i18n from '@/assets/lang/index';
import MyDialog from '@/plugins/component/MyDialog';
import MyToast from '@/plugins/component/MyToast';
import useClipboard from 'vue-clipboard3';
import { usePlatform } from "@/hooks";
import { shareSocialMedia, followSocialMedia, enterGame } from "@/api";

const { toClipboard } = useClipboard();

export const useHelpTool = () => {
  const doShare = (postData) => {
    shareSocialMedia(postData).then(res => {
      let url = res.data;
      if (window.$bridge) {
        window.$bridge.callHandler('share', JSON.stringify({
          method: 'app',
          url,
          type: 'url'
        }))
      } else {
        MyDialog.alert({ 
          message: i18n.global.t('notify.shareSuccess') 
        }).then(() => {// on confirm
          copy(url, false);
        });
      }
    }) 
  }

  const doFollow = (postData) => {
    followSocialMedia(postData).then(res => {
      let url = res.data;
      openBrowser(url, { showAlert: true });
    })
  }

  const doEnterGame = (gid) => {
    enterGame({
      gid,
    }).then(res => {
      let { playUrl, openMode } = res.data || {};
      if (openMode === 'browser') {
        openBrowser(playUrl, { showAlert: true });
      } else {
        openUrl(playUrl, { showAlert: true });
      }
    });
  }

  const copy = async(text, showToast = true) => {
    try {
      await toClipboard(text);
      if(showToast) {
        MyToast(i18n.global.t('notify.copySuccess'));
      }
    } catch (e) {
      console.error(e)
    }
  }

  const openUrl = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openUrl', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openBrowser = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openBrowser', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openUrlInWeb = (url, options = {}) => {
    if (options.showAlert || options.message) {
      MyDialog.alert({
        message: options.message ? options.message : i18n.global.t('msg.openBrowser'),
        confirmButtonText: i18n.global.t('common.go').toUpperCase(),
      })
      .then(() => {// on close
        let a = document.createElement('a');
        a.setAttribute('target','_blank');
        a.setAttribute('href', url);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } else {
      let a = document.createElement('a');
      a.setAttribute('target','_blank');
      a.setAttribute('href', url);
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  return { doShare, doFollow, doEnterGame, copy, openUrl, openBrowser };
}

import { createRouter, createWebHistory } from 'vue-router';
import { storage } from '@/utils/storage'

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'game',
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/user/welcome'),
  },
  {
    name: 'tab',
    path: '/tab',
    component: () => import('./layout/TabbarLayout'),
    children:[{
      name: 'game',
      path: '/game',
      component: () => import('./view/game/index'),
    }]
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (!storage.get('userInfo') 
    && to.name !== 'login' 
    // && to.name !== 'forgetPassword'
  ) {
    next({ name: 'login' });
  }
  next();
});

export default router;

import { defineStore } from 'pinia'
import { useUserStore } from '@/store'

export const usePopupStore = defineStore('popup', {
  state: () => {
    return {
      rewards: [],
      initData: null,
      showDeposit: false,
      showWithdraw: false,
      showLiveChat: false,
      showRecord: false,
      showEvent: false,
      showVip: false,
      showProfile: false,
      showBindSuperior: false,
      showSetting: false,
      showDaliyBonus: false,
      showReward: false,
      showLuckyWheel: false,
      showTask: false,
      showInbox: false,
      showSocialMedia: false,
      showSecurityCenter: false,
      showChangePassword: false,
      showTransactionPassword: false,
      showRedeem: false,
      showBindAccount: false,
    }
  },
  actions: {
    set(key, value = true) {
      // 判断提现和个人信息页面是否是游客打开
      if (key === 'showWithdraw' || key === 'showTransactionPassword' || key === 'showProfile' 
        || key === 'showSecurityCenter' || key === 'showChangePassword') {
        const userStore = useUserStore();
        if (userStore.guest) {
          this.showBindAccount = true;
          return false;
        }
      }
      this.initData = null;
      this[key] = value;
    },
    goToDeposit(initData) {
      this.initData = initData;
      this.showDeposit = true;
    },
    showRewards(rewards) {
      if(Array.isArray(rewards)) {
        this.rewards = rewards;
      } else {
        this.rewards = [{ type: "cash", amount: rewards }];
      }
      this.showReward = true;
    },
  }
})

import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "full-screen-cover"
};
const _hoisted_2 = ["src"];
import { ref, inject } from 'vue';
import { useEventListener } from '@vant/use';
export default {
  __name: 'FullScreenTip',
  setup(__props) {
    const hideCover = inject('hideCover');
    const gestureHideBar = () => {
      // 页面滚动 隐藏提示'滚动全屏展示手势'
      useEventListener('scroll', () => {
        let currentScrollTop = window.scrollY || document.documentElement.scrollTop;
        if (currentScrollTop > 50) {
          hideCover.value = true;
        }
      }, {
        target: window
      });

      // 判断屏幕方向
      useEventListener('resize', () => {
        let orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
        if (orientation === 'portrait') {
          hideCover.value = false;
        }
      }, {
        target: window
      });
    };
    gestureHideBar();
    return (_ctx, _cache) => {
      return !_unref(hideCover) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        class: "hand-gif",
        src: require('@/assets/gif/hand-move.gif')
      }, null, 8, _hoisted_2)])) : _createCommentVNode("", true);
    };
  }
};
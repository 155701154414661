export const useFullScreen = () => {
  const supportFull = () => {
    let el = document.documentElement;
    var requestFullscreen =
        el.requestFullscreen ||
        el.webkitRequestFullscreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
    var fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled;
    return !!(requestFullscreen && fullscreenEnabled);
  }
  //全屏，如果当前已经全屏，则操作无效
  const fullScreen = (callback) => {
    if (isFullScreen()) {
      return;
    }
    let el = document.documentElement;
    // 不同浏览器兼容方法调用
    if (el.requestFullScreen){
      el.requestFullScreen();
    } else if(el.webkitRequestFullScreen){
      el.webkitRequestFullScreen();
    } else if(el.mozRequestFullScreen){
      el.mozRequestFullScreen();
    } else if(el.msRequestFullScreen){
      el.msRequestFullScreen();
    } else {
      callback && callback();
    }
  }
  //获取当前全屏的元素
  const fullele = () => {
    return(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement ||
      document.mozFullScreenElement ||
      null);
  }
  //是否全屏
  const isFullScreen = () => {
    return !!(document.webkitIsFullScreen || fullele());
  }

  //退出全屏
  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
  return { supportFull, fullScreen, isFullScreen, exitFullScreen };
} 
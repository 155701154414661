import { showDialog, showConfirmDialog } from 'vant';
import 'vant/es/dialog/style';
import i18n from '@/assets/lang/index';

const confirm = param => {
  const { message, type, ...rest } = param;
  return showConfirmDialog({
    message: `<div class="my-dialog-header">${i18n.global.t('msg.notice')}</div>` +
             `<img class="my-dialog-icon" src="/images/notice.png" />` +
             `<div><p class="my-dialog-text">${message}</p></div>`,
    allowHtml: true,
    theme: 'round-button',
    confirmButtonText: i18n.global.t('common.confirm').toUpperCase(),
    cancelButtonText: i18n.global.t('common.cancel').toUpperCase(),
    ...rest,
    title: undefined,
  });
};

const alert = param => {
  const { message, type, ...rest } = param;
  return showDialog({
    message: `<div class="my-dialog-header">${i18n.global.t('msg.notice')}</div>` +
             `<img class="my-dialog-icon" src="/images/notice.png" />` +
             `<div><p class="my-dialog-text">${message}</p></div>`,
    allowHtml: true,
    theme: 'round-button',
    confirmButtonText: i18n.global.t('common.close').toUpperCase(),
    ...rest,
    title: undefined,
  });
};


export default { confirm, alert };
import { allowMultipleToast } from 'vant';
import Service from '@/components/Common/Service';
import MyToast from './MyToast';
import MyDialog from './MyDialog';

export { setupDirective } from '@/directive'

export function setComponents(app) {
  app.component('Service', Service);

  allowMultipleToast();
  
  app.config.globalProperties.$toast = MyToast;
  app.config.globalProperties.$myDialog = MyDialog;
}

// lang文件夹下的index.js 
// zh, en 分别为两种语言文件
import { createI18n } from 'vue-i18n';
import { Local as vLocal } from "@/assets/lang/vantLocale";
import { Local as mLocal } from "@/assets/lang/momentLocale";
import en from './en-US';
import vi from './vi-VN';

const i18n = createI18n({
  // legacy: false,
  // globalInjection: true,
  locale: localStorage.getItem('i18n') || 'en-US',
  allowComposition: true, // you need to specify that!
  messages: {
    'en-US': en,
    'vi-VN': vi,
  }
});

export function Local(mylang) {
  i18n.global.locale = mylang;
  vLocal(mylang);
  mLocal(mylang);
}

export default i18n;